// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

     $('.js-replace-img').each(function() {

       var src = $(this).find("img").attr("src");
       $(this).css("background-image","url("+src+")");
     });
    }

    jQuery(function() {
     if($(".js-replace-img")) {
       replaceSrc();
     }
    });


(function(){

  var s = {

    menuState: function() {

      $(this).toggleClass("cross");
      $(".nav-wrapper").toggleClass("open");

    },

    headerHeight: function() {

      if($(window).scrollTop() > 0) {

        return $('.main-header').height();
      } else {

        return $('.main-header').height() - 24;
      }
    },

    headerPosition: function() {

      var top = $(window).scrollTop();

      if(top > 72) {

        $(".main-header").addClass("min");
        $(".sub-nav").addClass("min");
        $(".top-bar").addClass("min");

      } else {

        $(".main-header").removeClass("min");
        $(".sub-nav").removeClass("min");
        $(".top-bar").removeClass("min");
      }
    },

    events: function() {
      $(window).scroll(this.headerPosition);
      $("#btnMobile").on("click",this.menuState);
    },

    init: function() {

      this.headerPosition();
      this.events();
    }

  };

  s.init();

})();

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 140
    }, 500);
});

// BTN FILTRES
jQuery('#btnMobileFiltre').click(function(e) {
  e.preventDefault();
  jQuery('form.searchandfilter').toggleClass('open');
  jQuery('#btnMobileFiltre').toggleClass('cross');
});

// HIDE FILTER
$( document ).ready(function() {
  checkEmpty();
  function checkEmpty(){
      var removed = [];
      for (var i = 0; i < document.querySelector('.searchandfilter ul').children.length; i++) {
          if (document.querySelector('.searchandfilter ul').children[i].querySelector('ul')) {
              if (document.querySelector('.searchandfilter ul').children[i].querySelector('ul').children.length == 0) {
                  removed.push(document.querySelector('.searchandfilter ul').children[i]);
                  document.querySelector('.searchandfilter ul').children[i].remove();
                  i--;
              }
          }
          console.log(removed);
      }
  }
});

// SEE INFOS CONTACT
jQuery('#co-see').click(function(e) {
  e.preventDefault();
  $(this).toggleClass("hide");
  jQuery('.co-hide').toggleClass('see');
});
